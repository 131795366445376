import React from 'react';
import jesh from '../assets/Team/jesh.jpeg';
import muhi from '../assets/Team/muhi.jpg';
import Rijutha from '../assets/Team/Rijutha.jpg';
import shiva from '../assets/Team/shiva.jpeg';
import Sourav from '../assets/Team/Sourav.jpg';
import tejas from '../assets/Team/tejas.jpg';
import AJ from '../assets/Team/AJ.jpg';
import Marquee from "react-fast-marquee";


const Page4 = () => {
  return (
    <div>
         <section id="ourTeam" className="bg-white lg:pb-36">
            <h1 className="text-3xl font-extrabold text-gray-900 lg:mb-10 text-center xl:pt-28 lg:pt-16">Meet Our Team</h1>
            <Marquee speed={125} className='justify-between' pauseOnHover>
                <div className="py-5 lg:mr-12 px-6">
                    <div className="slide text-center">
                        <div className="card">
                            <div className="card-content">
                                <a href="https://www.linkedin.com/in/jeshwanthnl/"><img className="mt-22" style={{transform: 'scale(1.5)'}} src={jesh} alt=""/></a>
                            </div>
                        </div>
                        <h1 className="text-xl font-bold pt-1">Jeshwanth N L</h1>
                        <span className="font-semibold"><i>Chief Executive Officer</i> <br/> Cyber Security Engineer</span>
                    </div>                
                </div>

                {/*<div className="py-5 lg:mr-12 px-6">
                    <div className="slide text-center">
                        <div className="card">
                            <div className="card-content">
                                <img style={{transform: "scale(2.1)"}} src={AJ} alt=""/>
                            </div>
                        </div>
                        <h1 className="text-xl font-bold pt-1">Ajay</h1>
                        <span className="font-semibold"><i>Chief Operating Officer</i> <br/>Marketing Head</span>
                    </div>               
                </div>*/}

                <div className="py-5 lg:mr-12 px-6">
                    <div className="slide text-center">
                        <div className="card">
                            <div className="card-content">
                                <a href="https://www.linkedin.com/in/sourav-ranj/"><img className="mt-64" style={{transform: 'scale(2.1)'}} src={Sourav} alt=""/></a>
                            </div>
                        </div>
                        <h1 className="text-xl font-bold pt-1">Sourav</h1>
                        <span className="font-semibold"><i>Social Media Manager</i> <br/>Content Creator</span>
                    </div>               
                </div>

                <div className="py-5 lg:mr-12 px-6">
                    <div className="slide text-center">
                        <div className="card">
                            <div className="card-content">
                                <a href="https://www.linkedin.com/in/rijutha-dhanasekaran-192a8b227"><img style={{transform: 'scale(3)', marginRight: '75px'}} src={Rijutha} alt=""/></a>
                            </div>
                        </div>
                        <h1 className="text-xl font-bold pt-1">Rijutha S</h1>
                        <span className="font-semibold"><i>Database Administrator</i> <br/>Data Analyst</span>
                    </div>               
                </div>

                <div className="py-5 lg:mr-12 px-6">
                    <div className="slide text-center">
                        <div className="card">
                            <div className="card-content">
                                <a href="https://www.linkedin.com/in/muhilanr/"><img className="mt-20" style={{transform: 'scale(3)', marginRight: '75px}'}} src={muhi} alt=""/></a>
                            </div>
                        </div>
                        <h1 className="text-xl font-bold pt-1">Muhilan</h1>
                        <span className="font-semibold"><i>SEO</i> <br/>Content Writer</span>
                    </div>               
                </div>

                <div className="py-5 lg:mr-12 px-6">
                    <div className="slide text-center">
                        <div className="card">
                            <div className="card-content">
                                <a href="https://www.linkedin.com/in/kokila-tejaswi-a43037199"><img className="mt-16" style={{transform: 'scale(1.5)', marginRight: '75px'}} src={tejas} alt=""/></a>
                            </div>
                        </div>
                        <h1 className="text-xl font-bold pt-1">Tejaswari</h1>
                        <span className="font-semibold"><i>UI/UX</i> <br/>Creative Director</span>
                    </div>               
                </div>

                <div className="py-5 lg:mr-12 px-6">
                    <div className="slide text-center">
                        <div className="card">
                            <div className="card-content">
                                <a href="https://www.linkedin.com/in/shiva-narayan-b439921b7/"><img className="mt-28" style={{transform: 'scale(3)', marginRight: '75px'}} src={shiva} alt=""/></a>
                            </div>
                        </div>
                        <h1 className="text-xl font-bold pt-1">Shiva Narayanan S</h1>
                        <span className="font-semibold"><i>Chief Technical Officer</i> <br/>Project Manager</span>
                    </div>               
                </div>
        </Marquee>
        
    </section>
    </div>
  )
}

export default Page4