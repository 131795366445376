import React from 'react';
import Heroo from '../assets/Heroo.png'; 

const Page1 = () => {
  return (
    <div>
        <section className="bg-white xl:w-full xl:pb-10">
        <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-24 lg:grid-cols-12 lg:pt-28">
            <div className="mr-auto place-self-center lg:col-span-7">
                <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl">Empowering Growth <br/>in the Digital Universe.</h1>
                <p className="max-w-2xl mb-6 font-light text-gray-700 lg:mb-8 md:text-lg lg:text-xl ">we offer a comprehensive suite of services tailored to meet all your digital needs. With a team of skilled professionals and a proven track record, we are here to empower your business for success in the digital universe.</p>
                
            </div>
            <div className="sm:hidden lg:mt-0 lg:col-span-5 lg:flex">
                <img src={Heroo} alt="hero image"/>
            </div>                
        </div>
    </section>
    </div>
  )
}

export default Page1