import React from 'react'
import Meeting from '../assets/Meeting.png';


const Page3 = () => {
  return (
    <div>
        <section id="wcu" className="2xl:pt-10">
        <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-40 lg:px-6 items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16 lg:pt-20">
            <img className="sm:hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex" src={Meeting} alt="feature img 2"/>
            <div className="text-gray-500 sm:text-lg">
                <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 ">Why Choose Us ?</h2>
                <p className="mb-8 font-light lg:text-xl">From web development to 24x7 support, hosting, and social media management, we have you covered. With a proven track record and personalized approach, we are your trusted partner in the digital universe. Elevate your online presence with us today.</p>
                <ul role="list" className="pt-8 space-y-5 border-t border-gray-200 my-7 ">
                    <li className="flex space-x-3">
                        <svg className="flex-shrink-0 w-5 h-5 text-purple-500 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                        <span className="text-base font-medium leading-tight text-gray-900 ">Comprehensive Services</span>
                    </li>
                    <li className="flex space-x-3">
                        <svg className="flex-shrink-0 w-5 h-5 text-purple-500 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                        <span className="text-base font-medium leading-tight text-gray-900 ">Proven Track Record</span>
                    </li>
                    <li className="flex space-x-3">
                        <svg className="flex-shrink-0 w-5 h-5 text-purple-500 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                        <span className="text-base font-medium leading-tight text-gray-900 ">Transparent Communication</span>
                    </li>
                    <li className="flex space-x-3">
                        <svg className="flex-shrink-0 w-5 h-5 text-purple-500 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                        <span className="text-base font-medium leading-tight text-gray-900 ">Personalized Approach</span>
                    </li>
                    <li className="flex space-x-3">
                        <svg className="flex-shrink-0 w-5 h-5 text-purple-500 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                        <span className="text-base font-medium leading-tight text-gray-900 ">Unwavering Support</span>
                    </li>
                </ul>
            </div>
        </div>
        </section>
 
    </div>
  )
}

export default Page3