'use client'
import Navbar from './components/navbar.js';
import Page1 from './components/Page1.js';
import Page2 from './components/Page2.js';
import Page3 from './components/Page3.js';
import Page4 from './components/Page4.js';
import Contact from './components/Contact.js';
import Footer from './components/Footer.js';

export default function Home() {
  return (
    <div>
      <Navbar />
      <Page1 />
      <Page2 />
      <Page3 />
      <Page4 />
      <Contact />
      <Footer />
    </div>
  )
}
